<template>
  <layout>
    <template slot="content">
      <div class="404">
        <h1>404 Recurso no encontrado!</h1>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'

export default {
  components: {
    Layout
  }
}
</script>
